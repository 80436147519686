import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ImpressumComponent } from "./impressum/impressum.component";
import { DataService } from "./services/data.service";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { HomeComponent } from "./home/home.component";
import { AngularFullpageModule } from "@fullpage/angular-fullpage";
import { ImageDirective } from "./directives/image.directive";
import { ImageService } from "./services/image.service";

@NgModule({
  declarations: [
    ImageDirective,
    HomeComponent,
    AppComponent,
    ImpressumComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    AngularFullpageModule,
  ],
  providers: [DataService, ImageService],
  bootstrap: [AppComponent],
})
export class AppModule {}
