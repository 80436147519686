import { NgModule } from "@angular/core";
import {
  Routes,
  RouterModule,
  Router,
  ActivatedRoute,
  NavigationEnd,
} from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { ImpressumComponent } from "./impressum/impressum.component";

const routes: Routes = [
  {
    path: "",
    component: HomeComponent,
  },
  { path: "impressum", component: ImpressumComponent },

  { path: "**", redirectTo: "/" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
