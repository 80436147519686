<div class="grid grid-10">
  <div class="grid-3-8">
    <router-outlet></router-outlet>
  </div>

  <div class="nav grid-9-10">
    <div class="small-flex">
      <a id="nav-lebenslauf" href="/#leoni">
        <img src="/assets/nav/lebenslauf.png" />
      </a>
      <a id="nav-portfolio" href="/#portfolio">
        <img src="/assets/nav/portfolio.png" />
      </a>
    </div>
    <div class="dotted"></div>
    <a id="nav-contact" href="/#kontakt">
      <img src="/assets/nav/contact.png" />
    </a>
  </div>
</div>

<div class="img-detail-view-container" [class.show]="imageViewContainerVisible">
  <span class="img-detail-view-container--close-button" (click)="closeImage()"
    ><p class="i">* close *</p></span
  >
  <div class="img-detail-view-container--image-container">
    <img [src]="Image" *ngIf="Image" />
  </div>
</div>
