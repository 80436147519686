import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class DataService {
  constructor(private httpClient: HttpClient) {
    console.log(environment.apiUrl);
  }

  private getUrl(uri: string): string {
    if (environment.apiUrl) {
      return environment.apiUrl;
    }

    let protocol;
    let port;
    switch (document.location.protocol) {
      case "https:": {
        protocol = "https:";
        port = ":443";
        break;
      }
      default: {
        protocol = "http:";
        port = ":80";
        break;
      }
    }
    return protocol + "//" + document.location.hostname + port + "/" + uri;
  }
}
