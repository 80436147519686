import { Component, ElementRef, HostListener, ViewChild } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import {
  options,
  fullpage_api,
} from "fullpage.js/dist/fullpage.extensions.min";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent {
  public config: options;
  public fullpage_api: fullpage_api;

  constructor(private router: Router) {
    // for more details on config options please visit fullPage.js docs
    this.config = {
      // fullpage options
      anchors: [
        "leoni",
        "lebenslauf",
        "portfolio",
        "bernsteinhonig",
        "narzissus",
        "moebelkatalog",
        "midori-tea",
        "naturalflow",
        "entre",
        "entre-1",
        "leichte-muh",
        "punch",
        "punch-wireframe",
        "punch-animation",
        "plakataktivismus",
        "plakataktivismus-1",
        "plakataktivismus-2",
        "plakataktivismus-3",
        "wunderhuebsch",
        "fotografie",
        "fotografie-1",
        "fotografie-2",
        "kontakt",
      ],
      menu: "#menu",
      responsiveWidth: 800,

      // fullpage callbacks
      afterResize: () => {
        console.log("After resize");
      },

      afterLoad: (origin, destination, direction) => {
        const punchVideo = document.getElementById("punch-animation-video");
        const animationVideo1 = document.getElementById("animation-video-1");
        const animationVideo2 = document.getElementById("animation-video-2");
        const animationVideo3 = document.getElementById("animation-video-3");

        if (destination.anchor == "punch-animation") {
          this.playVideo(punchVideo);
        } else {
          this.stopVideo(punchVideo);
        }

        if (destination.anchor == "plakataktivismus-1") {
          animationVideo1.dataset.showPlayButton = "false";
          this.playVideo(animationVideo1)
            .then()
            .catch(() => {
              animationVideo1.dataset.showPlayButton = "true";
            });
        } else {
          this.stopVideo(animationVideo1);
        }
        if (destination.anchor == "plakataktivismus-2") {
          this.playVideo(animationVideo2);
        } else {
          this.stopVideo(animationVideo2);
        }
        if (destination.anchor == "plakataktivismus-3") {
          this.playVideo(animationVideo3);
        } else {
          this.stopVideo(animationVideo3);
        }
      },
    };
  }

  async playVideo(video) {
    return video.play().catch(() => {
      video.controls = true;
    });
  }
  stopVideo(video) {
    video.pause();
    video.currentTime = 0;
  }

  ngOnInit() {}

  getRef(fullPageRef) {
    this.fullpage_api = fullPageRef;
  }
}
