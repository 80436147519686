import { Component, ElementRef, HostListener, ViewChild } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
import {
  options,
  fullpage_api,
} from "fullpage.js/dist/fullpage.extensions.min";
import { ImageService } from "./services/image.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "Hiptmair";
  get imageViewContainerVisible() {
    return this.imageService.imageUrl != null;
  }

  constructor(private imageService: ImageService) {}

  get Image() {
    return this.imageService.imageUrl;
  }
  closeImage() {
    this.imageService.imageUrl = null;
  }
}
