<div
  fullpage
  id="fullpage"
  class="grid-3-8"
  [options]="config"
  (ref)="getRef($event)"
>
  <div class="section">
    <div class="grid">
      <div class="grid-1-4">
        <img src="/assets/img/0_lebenslauf_small.jpg" class="sideimage" />
      </div>
      <div class="grid-5-10 space-between">
        <div>
          <h1>Leoni Hiptmair</h1>
          <p>
            Meine Kreativität und das Zeichnen brachten mir schon immer viel
            Freude. Daneben verbringe ich viel Zeit draußen wo ich schöne Plätze
            und interessante Pflanzen suche. Außer dem Lesen und (Video)-Spiele
            mit Freunden spielen, sammle ich gerne, etwas zu exzessiv,
            Zimmerpflanzen.
          </p>
          <p>
            Besonderes Interesse zeige ich an der Kunstgeschichte, der
            griechischen Mythologie und sozial-gesellschaftlichen Themen. Ich
            verschaffe mir als jüngstes Mitglied Gehör im Oberschlierbacher
            Kulturverein und helfe beim Organisieren.
          </p>
          <p>
            Da mein Wissensdurst praktisch nicht stillbar ist, informiere ich
            mich immer wieder über neue Dinge. Das neueste dieser Topics ist das
            Nähen, welches ich mir selbst beibringe.
          </p>
        </div>

        <div class="hide-small">
          <p class="i">* zum umblättern scrollen *</p>
        </div>
      </div>
    </div>
    <p class="footline">leoni hiptmair > lebenslauf</p>
  </div>
  <div class="section">
    <div class="flowchart">
      <div class="flowchart-entry">
        <div class="flowchart-label">2002</div>
        <p class="flowchart-content">
          Geboren am <b>12. August 2002</b> in Kirchdorf an der Krems
        </p>
      </div>
      <div class="flowchart-entry">
        <div class="flowchart-label">2008</div>
        <p class="flowchart-content"><b>Kreativ Volksschule</b> Schlierbach</p>
      </div>
      <div class="flowchart-entry">
        <div class="flowchart-label">2012</div>
        <p class="flowchart-content"><b>BRG/BORG</b> Kirchdorf</p>
      </div>
      <div class="flowchart-entry">
        <div class="flowchart-label">2016</div>
        <p class="flowchart-content">
          <b>HTL1 Linz</b> Abteilung Grafik und Kommunikationsdesign
        </p>
      </div>
      <div class="flowchart-entry">
        <div class="flowchart-label">2018</div>
        <p class="flowchart-content">
          <b>Praktikum: </b> Marketing&shy;ab&shy;tei&shy;lung bei Haidlmair
          GmbH (4 Wochen)
        </p>
      </div>
      <div class="flowchart-entry">
        <div class="flowchart-label">2019</div>
        <p class="flowchart-content">
          <b>Praktikum: </b> Werbeagentur Mach Werbung (4 Wochen)
        </p>
      </div>
      <div class="flowchart-entry">
        <div class="flowchart-label">2021</div>
        <p class="flowchart-content">
          <b>Abschluss: </b> HTL1 Linz, mit ausgezeichneten Erfolg
        </p>
      </div>
      <div class="flowchart-standalone">
        <h1>Skills</h1>
        <h3>Programme:</h3>
        <p>
          InDesign, Photoshop, Illustrator, After Effects, Bridge, Dreamweaver,
          Premiere, Figma, Microsoft Office
        </p>
        <h3>Anderes:</h3>
        <p>Fotografie, analoge Drucktechniken, Führerschein B</p>
        <h3>Sprachen:</h3>
        <p>Deutsch, Englisch</p>
      </div>
    </div>
    <p class="footline">leoni hiptmair > lebenslauf</p>
  </div>
  <div class="section">
    <div class="grid">
      <div class="grid-1-6 space-between">
        <div>
          <h1>Bernstein Honig</h1>
          <h2>Corporate Design</h2>
          <p>
            Im Zuge der Diplomarbeit, wurde ein Corporate Design eines
            imaginären Imkerei-Betriebes erstellt. Bernstein Honig ist eine
            Marke die jung, modern und umweltbewusst ist, aber den Bezug zur
            Region und Tradition nicht verloren hat. Neben der Ausarbeitung des
            Logos wurden Visitenkarten, sowie Briefpapier und Kuverts gestaltet.
          </p>
          <p>
            Der zweite Teil der Diplomarbeit befasst sich mit dem Produktdesign
            für drei Honig Sorten und wurde von meinem Diplomarbeitspartner
            (Simon Tretter) ausgearbeitet.
          </p>
        </div>
      </div>
      <div class="grid-7-10">
        <span image class="sideimage img-desktop" showHeader="true">
          <img src="/assets/img/1_portfolio_visitenkarte_small.jpg" />
        </span>
        <span image class="sideimage img-mobile">
          <img src="/assets/img/1_portfolio_visitenkarte.jpg"
        /></span>
      </div>
    </div>
    <p class="footline">
      leoni hiptmair > Corporate design > Bernsteinhonig > visitenkarte
    </p>
  </div>
  <div class="section">
    <div class="grid">
      <div class="grid-4-9 flex">
        <span image class="sideimage">
          <img
            src="/assets/img/2_portfolio_briefpapier.jpg"
            style="height: 40vh; margin-bottom: 1rem"
          />
        </span>
        <span class="sideimage" image>
          <img src="/assets/img/2_portfolio_kuvert.jpg" style="height: 25vh" />
        </span>
      </div>
    </div>
    <p class="footline">
      leoni hiptmair > Corporate design > Bernsteinhonig > visitenkarte
    </p>
  </div>
  <div class="section">
    <div class="grid">
      <div class="grid-1-6 space-between">
        <div>
          <h1>Narzissus</h1>
          <h2>Typografie & Illustration</h2>
          <p>
            Als völlig offenes Projekt entstand das Plattencover der imaginären
            Band Narzissus. Grund der Übung war das Einsetzen von
            Open-Font-Varianten, welche zuvor im Unterricht erlernt wurden. In
            welcher Form diese angewendet werden, konnte selbst entschieden und
            erarbeitet werden.
          </p>
          <p>
            Die Illustration entstand in Illustrator und die restlichen Elemente
            wurden später in InDesign hinzugefügt.
          </p>
        </div>
      </div>
      <div class="grid-7-10">
        <span image class="sideimage img-desktop">
          <img src="/assets/img/3_portfolio_narzissus_small.jpg" />
        </span>
        <span image class="sideimage img-mobile">
          <img src="/assets/img/3_portfolio_narzissus.jpg"
        /></span>
      </div>
    </div>
    <p class="footline">
      leoni hiptmair > Typografie und illustration > narzissus > plattencover
    </p>
  </div>
  <div class="section">
    <div class="grid">
      <div class="grid-1-6 space-between">
        <div>
          <h1>Möbel&shy;ka&shy;ta&shy;log</h1>
          <h2>Layout & Photoshop</h2>
          <p>
            Die Aufgabe dieses Projekts war es 12 verschiedene Möbelstücke auf
            drei Seiten eines Produktkatalogs unterzubringen. Die Seiten sollten
            möglichst unterschiedlich aussehen, aber trotzdem einen grafischen
            Zusammenhalt erschaffen. Im Vorhinein wurde das Layout in Skizzen
            geplant um alle Informationen unterzubringen.
          </p>
          <p>
            Das zweite Kapitel Headbild, für das Thema Leuchten und Lampen,
            entstand aus dem Hintergrund des ersten Headbildes, einem
            Produktfoto und einigen Photoshop-ebenen.
          </p>
        </div>
      </div>
      <div class="grid-7-10 flex">
        <span image class="sideimage moebelkatalog">
          <img
            src="/assets/img/4_portfolio_moebelkatalog1.jpg"
            style="margin-bottom: 1rem"
          />
        </span>
        <span image class="sideimage moebelkatalog">
          <img
            src="/assets/img/4_portfolio_moebelkatalog2.jpg"
            style="margin-bottom: 1rem"
          />
        </span>
        <span image class="sideimage moebelkatalog">
          <img src="/assets/img/4_portfolio_moebelkatalog3.jpg" />
        </span>
      </div>
    </div>
    <p class="footline">
      leoni hiptmair > layout und photoshop > moebelkatalog
    </p>
  </div>
  <div class="section">
    <div class="grid">
      <div class="grid-1-6 space-between">
        <div>
          <h1>Mi&shy;do&shy;ri Tea</h1>
          <h2>Marketing strategie</h2>
          <p>
            „Midori Tea“ ist eine energiespendende Grün Tee Limonade, eines
            erfundenen Wiener Betriebes. Sie sind umweltbewusst und versuchen
            möglichst alle Produkte regional sowie von kleinen Bauern zu
            beziehen.
          </p>
          <p>
            Der Grundnutzen der beworben wird, ist die aufputschendie Qualität
            der Limonade, die aber nicht ungesund ist, wie viele andere
            Alternativen. Beim Trinken dieses Produktes werden Körper und Geist
            angeregt, ohne nervige „Hibbeligkeit“ oder Rastlosigkeit.
          </p>
          <p>
            Das Produkt ist vor allem an junge, erwachsene, urbane Personen
            gewandt, mit Interesse an Umweltbewusstsein.
          </p>
          <p>
            Um die Zielgruppe anzusprechen, soll hauptsächlich im Internet
            Werbung geschalten werden. Aber auch analoge Werbung sollte
            besonders an Orten an denen sich umweltbewusste und kreative junge
            Menschen aufhalten platziert werden. Die Skizze zeigt wie das Plakat
            schlussendlich aussehen könnte.
          </p>
        </div>
      </div>
      <div class="grid-7-10">
        <span image class="sideimage img-desktop">
          <img src="/assets/img/5_portfolio_midori-tea_small.jpg" />
        </span>
        <img
          src="/assets/img/5_portfolio_midori-tea.jpg"
          class="sideimage img-mobile"
        />
      </div>
    </div>
    <p class="footline">leoni hiptmair > marketing strategie > midori tea</p>
  </div>
  <div class="section">
    <div class="grid">
      <div class="grid-1-6 space-between">
        <div>
          <h1>Na&shy;tural&shy;flow</h1>
          <h2>plakat design und typografie trends</h2>
          <p>
            Für dieses Projekt wurde ein freiwählbarer Typografie Trend aus 2021
            ausgearbeitet. Die Form der Gestaltung, also ob Plakat,
            Produktdesign, etc., war selbst zu entscheiden und zu erfinden.
          </p>
          <p>
            Der dargestellte Typografie Trend ist Liquid Typografie, also die
            Verflüssigung von Schriften. Erzielt wurde dieser Effekt, durch das
            Skizzieren der einzelnen Buchstaben um diese in Photoshop mit einem
            Beschneidungspfad nachzubauen. Dieser wurde dann als Maske verwendet
            um das Aussehen des Plakats zu erzielen.
          </p>
        </div>
      </div>
      <div class="grid-7-10">
        <span image class="sideimage img-desktop">
          <img src="/assets/img/6_portfolio_naturalflow_small.jpg"
        /></span>
        <img
          src="/assets/img/6_portfolio_naturalflow.jpg"
          class="sideimage img-mobile"
        />
      </div>
    </div>
    <p class="footline">
      leoni hiptmair > plakat design und typografie trends > naturalflow
    </p>
  </div>
  <div class="section">
    <div class="grid">
      <div class="grid-1-6 space-between">
        <div>
          <h1>Entre</h1>
          <h2>konzept & magazin gestalltung</h2>
          <p>
            Das Projekt beschäftigt sich mit der Konzeption sowie dem Layout
            eines Magazins. Erstellt wurden die Cover Seiten von zwei Ausgaben
            und die Inhaltsseite von einer der Ausgaben.
          </p>
          <p>
            „entre“ ist ein Kulturmagazin für den Austausch zwischen deutsch-
            und französischsprachigen Menschen. Die Artikel werden auf der einen
            Seite auf Deutsch und auf der anderen auf Französisch abgedruckt.
            Die Themen drehen sich um die Kulturszenen in den beiden
            Sprachgebieten.
          </p>
          <p>
            Die Covers sollten verwandt aussehen, damit sie wiedererkannt
            werden, aber trotzdem abwechslungsreich bleiben.
          </p>
        </div>
      </div>
      <div class="grid-7-10 flex">
        <div style="display: flex; flex-flow: column">
          <span image>
            <img
              src="/assets/img/7_portfolio_magazincover1.png"
              style="margin-bottom: 1rem; margin-left: auto"
              class="coverimage entre-image"
          /></span>
          <span image>
            <img
              src="/assets/img/7_portfolio_magazincover2.png"
              style="margin-left: auto"
              class="coverimage entre-image"
            />
          </span>
        </div>
      </div>
    </div>
    <p class="footline">
      leoni hiptmair > konzept und magazin gestalltung > entre > cover
    </p>
  </div>
  <div class="section">
    <div class="grid">
      <span image>
        <img
          src="/assets/img/8_portfolio_inhaltseite.png"
          class="coverimage grid-1-10"
        />
      </span>
    </div>
    <p class="footline">
      leoni hiptmair > konzept und magazin gestalltung > entre > inhaltsseite
    </p>
  </div>
  <div class="section">
    <div class="grid">
      <div class="grid-1-6 space-between">
        <div>
          <h1>Die Leichte Muh</h1>
          <h2>Verpackungsdesign & Illustration</h2>
          <p>
            Entstanden ist das Projekt im Zuge des Wettbewerbs ausgetragen von
            „Die leichte Muh“. Hierfür entstand ein neues Verpackungsdesign für
            den Milchkarton als Special Edition.
          </p>
          <p>
            Das Konzept bezieht sich auf den Namen „Die leichte Muh“ die also so
            leicht ist, dass sie sich in der Schwerelosigkeit des Weltalls
            wiederfindet. Obwohl die Verpackung unkonventionell dunkler ist,
            sticht diese Verpackung aus dem Milchregal heraus. Als Special
            Edition macht sich etwas ungewöhnliches besonders gut.
          </p>
          <p>
            Die Illustration entstand in Photoshop und versteckt auf den vier
            Seiten verschiedene Speisen, in der „Die leichte Muh”-Milch drinnen
            ist.
          </p>
        </div>
      </div>
      <div class="grid-7-10">
        <span class="sideimage img-desktop" image>
          <img src="/assets/img/9_portfolio_leichte-muh_small.jpg" />
        </span>
        <span class="sideimage img-mobile" image>
          <img src="/assets/img/9_portfolio_leichte-muh.jpg" />
        </span>
      </div>
    </div>
    <p class="footline">
      leoni hiptmair > Verpackungsdesign und Illustration > die leichte muh
    </p>
  </div>
  <div class="section">
    <div class="grid">
      <div class="grid-1-6 space-between">
        <div>
          <h1>Punch.</h1>
          <h2>Corporate Design & web</h2>
          <p>
            Die punch. Agency entstand als Teamprojekt mit dem Schwerpunkt
            Webauftritt und Corporate Design. Leiss Katharina, Schlögelhofer
            Teresa, Schwarzinger Fabian und ich erarbeiteten zuerst gemeinsam
            das Konzept sowie Name und Feeling der Agency.
          </p>
          <p>
            Je nach Expertise teilten wir die Aufgaben zu, sodass jeder das
            Beste zu dem Projekt beitragen konnte. Meine Bereiche waren die
            Farben sowie das Wireframe für die Website. Schlussendlich musste
            jeder eine Logo Animation erstellen, mit dem erarbeiteten Logo und
            der Bildmarke.
          </p>
          <p>
            Über den Content der Website konnte eigenständig entschieden werden
            und sollte herausgearbeitet werden.
          </p>
        </div>
      </div>
      <div class="grid-7-10">
        <span class="sideimage img-desktop" image>
          <img src="/assets/img/10_portfolio_CI_small.jpg" />
        </span>
        <span class="sideimage img-mobile" image>
          <img src="/assets/img/10_portfolio_CI.jpg" />
        </span>
      </div>
    </div>
    <p class="footline">
      leoni hiptmair > Corporate Design und web > punch. > coporate design sheet
    </p>
  </div>
  <div class="section img-desktop">
    <div class="grid">
      <div class="grid-4-6">
        <span image>
          <img
            src="/assets/img/11_portfolio_wireframe1.jpg"
            class="sideimage mockupimage"
          />
        </span>
      </div>
      <div class="grid-7-9">
        <span image>
          <img
            src="/assets/img/11_portfolio_wireframe2.jpg"
            class="sideimage mockupimage"
          />
        </span>
      </div>
    </div>
    <p class="footline">
      leoni hiptmair > Corporate Design und web > punch. > webdesign wireframe
    </p>
  </div>

  <div class="section">
    <div class="grid">
      <div class="grid-1-10">
        <video
          src="/assets/img/12_portfolio_animation.mp4"
          id="punch-animation-video"
          autoplay
        ></video>
      </div>
    </div>
    <p class="footline">
      leoni hiptmair > Corporate Design und web > punch. > webdesign wireframe
    </p>
  </div>
  <div class="section">
    <div class="grid">
      <div class="grid-1-6 space-between">
        <div>
          <h1>Pla&shy;kat&shy;akti&shy;vismus</h1>
          <h2>Konzept, Illustration & Animation</h2>
          <p>
            Das Ziel des Projekts war, ein sozialkritisches oder
            umweltbetreffendes Thema zu beleuchten und Plakate ohne eine
            vorgegebene Form zu erstellen.
          </p>
          <p>
            Entschieden habe ich mich für Queer-Empowerment, mit den Motos „Sich
            selbst lieben lernen“, „Andere lieben lernen“ und „Aus der
            Vergangenheit lernen“. Das Aussehen ist an den russische Propaganda
            Plakate angelehnt, da dies mit der Ideologie sehr konträr ist.
          </p>
          <p>
            Um den Illustrationen Leben ein zu hauchen, wurden sie minimal
            animiert.
          </p>
        </div>
        <div style="margin-top: 5rem" class="img-desktop">
          <p class="i">* weiter scrollen um<br />animationen zu starten *</p>
        </div>
      </div>
    </div>
    <p class="footline">
      leoni hiptmair > konzept, illustration und animation > plakataktivismus
    </p>
  </div>
  <div class="section">
    <div class="grid">
      <div class="grid-1-12">
        <video
          src="/assets/img/14_portfolio_plakataktivismus.mp4"
          id="animation-video-1"
          class="styled"
          autoplay
        ></video>
      </div>
    </div>
    <p class="footline">
      leoni hiptmair > konzept, illustration und animation > plakataktivismus >
      play
    </p>
  </div>
  <div class="section">
    <div class="grid">
      <div class="grid-1-12">
        <video
          src="/assets/img/15_portfolio_plakataktivismus.mp4"
          id="animation-video-2"
          class="styled"
          loop
          autoplay
        ></video>
      </div>
    </div>
    <p class="footline">
      leoni hiptmair > konzept, illustration und animation > plakataktivismus >
      play
    </p>
  </div>
  <div class="section">
    <div class="grid">
      <div class="grid-1-12">
        <video
          src="/assets/img/16_portfolio_plakataktivismus.mp4"
          id="animation-video-3"
          class="styled"
          loop
          autoplay
        ></video>
      </div>
    </div>
    <p class="footline">
      leoni hiptmair > konzept, illustration und animation > plakataktivismus >
      play
    </p>
  </div>
  <div class="section">
    <div class="grid">
      <div class="grid-1-6 space-between">
        <div>
          <h1>wunder&shy;hübsch</h1>
          <h2>web Design</h2>
          <p>
            In Zusammenarbeit mit
            <a class="nostyle" href="https://www.hiptmairit.at/"
              >Jonas Hiptmair</a
            >
            und
            <a class="nostyle" href="https://www.wunderhuebsch.at/"
              >Wunderhübsch</a
            >
            entstand eine Website mit Shop-Funktion.
          </p>
          <p>
            Die Schriftmarke war bereits vorgegeben und die Produktfotos wurden
            bereitgestellt. Um die Website zu gestalten wurde ein Wireframe
            erstellt. Umgesetzt wurde dieses dann von
            <a class="nostyle" href="https://www.hiptmairit.at/"
              >Jonas Hiptmair</a
            >.
          </p>
          <p>
            <a class="nostyle" href="https://www.wunderhuebsch.at/"
              >Wunderhübsch</a
            >
            stellt Makramees, Geschenke und Accessoires in Handarbeit her.
          </p>
          <p style="margin-top: 4rem" class="center">
            <a href="https://www.wunderhuebsch.at" target="_blank"
              >* link zur website *
            </a>
          </p>
        </div>
      </div>
      <div class="grid-7-10 img-desktop">
        <span image>
          <img
            src="/assets/img/17_portfolio_wunderhübsch_small.jpg"
            class="coverimage sideimage flexibleimage"
        /></span>
      </div>
    </div>
    <p class="footline">leoni hiptmair > web design > wunderhübsch</p>
  </div>
  <div class="section">
    <div class="grid">
      <div class="grid-1-6 space-between">
        <div>
          <h1>Fotografie</h1>
          <h2>analog & Digital</h2>
          <p>
            Durch den Fotografie-Unterricht war es möglich viel verschiedenes
            auszuprobieren, wie Studio-, Porträt- und Analogfotografie.
          </p>
        </div>
        <div style="margin-top: 5rem" class="img-desktop">
          <p class="i">* weiter scrollen um bilder aufzurufen *</p>
        </div>
      </div>
    </div>
    <p class="footline">leoni hiptmair > analog und digital > fotografie</p>
  </div>
  <div class="section">
    <div class="grid img-grid">
      <div class="grid-1-2">
        <span image class="img-desktop" style="height: 100%">
          <img src="/assets/img/19_portfolio_foto1_small.jpg"
        /></span>
        <img src="/assets/img/19_portfolio_foto1.jpg" class="img-mobile" />
      </div>
      <div class="grid-3-4">
        <span image class="img-desktop" style="height: 100%">
          <img src="/assets/img/19_portfolio_foto2_small.jpg"
        /></span>
        <img src="/assets/img/19_portfolio_foto2.jpg" class="img-mobile" />
      </div>
      <div class="grid-5-10">
        <span image class="img-desktop" style="height: 100%">
          <img src="/assets/img/19_portfolio_foto3_small.jpg"
        /></span>
        <img src="/assets/img/19_portfolio_foto3.jpg" class="img-mobile" />
      </div>
    </div>

    <p class="footline">leoni hiptmair > analog und digital > fotografie</p>
  </div>
  <div class="section">
    <div class="grid img-grid">
      <div class="grid-1-4">
        <p>
          Diese Bilder erlangten je den 8.&nbsp;Platz bei der
          Jugend&shy;staats&shy;meister&shy;schaft der Fotografie 2019
          (Altersklasse II), in den Kategorien “Schwarz-Weiß” und “Kreativ”.
        </p>
      </div>
      <div class="grid-5-8">
        <span image class="img-desktop" style="height: 100%">
          <img src="/assets/img/20_portfolio_foto1_small.jpg" />
        </span>
        <img src="/assets/img/20_portfolio_foto1.jpg" class="img-mobile" />
      </div>
      <div class="grid-9-10">
        <span image class="img-desktop" style="height: 100%">
          <img src="/assets/img/20_portfolio_foto2_small.jpg" />
        </span>
        <img src="/assets/img/20_portfolio_foto2.jpg" class="img-mobile" />
      </div>
    </div>

    <p class="footline">
      leoni hiptmair > analog und digital > fotografie >
      jugendstaatsmeisterschaft
    </p>
  </div>

  <div class="section">
    <div class="grid">
      <div
        class="space-between grid-1-10"
        style="min-height: 72vh"
        id="contact-container"
      >
        <div>
          <h1>Kontakt</h1>
          <p style="margin-bottom: 0.25rem">
            <a class="nostyle" href="mailto:leoni.hiptmair@gmail.com"
              ><img src="/assets/icons/mail.jpg" class="icon" />
              <span>leoni.hiptmair@gmail.com</span></a
            >
          </p>
          <p style="margin-bottom: 0.25rem; margin-top: 0.25rem">
            <a class="nostyle" href="tel:+436649232023">
              <img src="/assets/icons/phone.jpg" class="icon" />
              <span>+43 664 923 20 23</span></a
            >
          </p>
          <p style="margin-top: 0.25rem">
            <a
              class="nostyle"
              href="https://www.instagram.com/leoni.ohne.ie/"
              target="_blank"
            >
              <img src="/assets/icons/insta.jpg" class="icon" />
              <span>leoni.ohne.ie</span>
            </a>
          </p>
          <p class="nostyle">
            <img src="/assets/icons/location.jpg" class="icon" /><span
              >Kaiserleithen 2 <br />4554 Oberschlierbach</span
            >
          </p>
        </div>

        <div style="padding-bottom: 3rem">
          <p style="text-align: center; padding-bottom: 2em">
            <a
              href="/assets/downloads/hiptmair-leoni_portfolio.pdf"
              target="_blank"
              style="max-width: 314px; display: inline-block"
              class="active"
              >* hier klicken um portfolio als pdf herunterzuladen *
            </a>
          </p>
          <p style="text-align: center">
            <a routerLink="/impressum">* Impressum * </a>
          </p>
        </div>
      </div>
    </div>
    <p class="footline">
      leoni hiptmair > Kontakt und Download > zum kontaktieren
    </p>
  </div>
</div>
