import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
} from "@angular/core";
import { ImageService } from "../services/image.service";

@Directive({
  selector: "[image]",
})
export class ImageDirective implements AfterViewInit, OnDestroy {
  static dataSet = { showInfoHeader: "true" };
  constructor(private elem: ElementRef, private imageService: ImageService) {}
  interval;
  ngAfterViewInit(): void {
    if (this.showHeader != "false")
      this.interval = setInterval(this.setShowInfoHeaderConfig.bind(this), 100);
    else if (this.elem?.nativeElement && this.elem.nativeElement.dataset)
      this.elem.nativeElement.dataset.showInfoHeader = "false";
  }
  ngOnDestroy(): void {
    if (this.interval) clearInterval(this.interval);
  }
  setShowInfoHeaderConfig() {
    if (this.elem?.nativeElement) {
      if (this.elem.nativeElement.dataset)
        this.elem.nativeElement.dataset.showInfoHeader =
          ImageDirective.dataSet.showInfoHeader;
    }
  }

  @HostListener("click", ["$event"]) onClick($event) {
    ImageDirective.dataSet.showInfoHeader = "false";
    this.imageService.imageUrl =
      this.elem.nativeElement.children[0].src.replace("_small.", ".");
  }

  @Input() public showHeader = "false";
}
