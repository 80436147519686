import { Component, OnInit } from "@angular/core";
import { Title, Meta } from "@angular/platform-browser";

@Component({
  selector: "app-impressum",
  templateUrl: "./impressum.component.html",
  styleUrls: ["./impressum.component.scss"],
})
export class ImpressumComponent implements OnInit {
  constructor(private title: Title, private meta: Meta) {}

  ngOnInit() {
    this.title.setTitle("Impressum");
    this.meta.updateTag({
      name: "description",
      content:
        "Du möchtest deine eigene persönliche Website? // Schulio - Das Verleihsystem für Schulbibliotheken // Jonas Hiptmair",
    });
    this.meta.updateTag({
      name: "keywords",
      content: "Jonas Hiptmair, Impressum",
    });
    this.meta.updateTag({
      name: "twitter:description",
      content:
        "Du möchtest deine eigene persönliche Website? // Schulio - Das Verleihsystem für Schulbibliotheken // Jonas Hiptmair",
    });
    this.meta.updateTag({
      name: "og:description",
      content:
        "Du möchtest deine eigene persönliche Website? // Schulio - Das Verleihsystem für Schulbibliotheken // Jonas Hiptmair",
    });
  }
}
