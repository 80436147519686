<div class="grid">
  <div class="pb-5">
    <h1 class="small pt-5" style="padding-bottom: 0.5em">Impressum</h1>
    <p>
      Leoni Hiptmair <br />
      Grafikdesignerin
    </p>
    <p>Kaiserleithen 2<br />4554 Oberschlierbach<br />Österreich</p>
    <p>
      <a href="tel:00436649233023">tel: 0664 9233023</a><br /><a
        href="mailto:leoni.hiptmair@gmail.com"
        >email: leoni.hiptmair@gmail.com</a
      >
    </p>
    <br /><br />
    <p>
      Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen
      dem Urheberrecht. Falls notwendig, werden wir die unerlaubte Nutzung von
      Teilen der Inhalte unserer Seite rechtlich verfolgen.
    </p>
    <h1 style="font-size: 1.5rem; margin-top: 2rem">Website by:</h1>
    <p>
      <a
        href="https://www.hiptmairit.at"
        target="_blank"
        class="nostyle"
        style="color: #5c59f0 !important"
        >Jonas Hiptmair</a
      ><br /><a
        class="nostyle"
        href="https://www.instagram.com/jonas.hiptmair.it/"
        target="_blank"
        >Instagram</a
      >
    </p>
  </div>
</div>
